
import request from '@/utils/request'

// 年检列表
export function getWxPz(data) {
  return request({
    url: '/wechat/jssdk',
    method: 'get',
    params: data
  })
}
