<template>
  <div id="top" class="pageBox">
    <div v-if="isShow" class="loadingImgBox">
      <div class="loadingImg-item">
        <img src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/startLoading.png" alt="">
        <div class="loading-num">
          {{ progress }}%
        </div>
      </div>
    </div>
    <!-- 长图 -->
    <div v-show="!isShow" class="longBanner">
      <img class="" src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/longbanner.jpg" alt="">

      <!-- 手 -->
      <!-- <div class="shouClass" /> -->
      <!-- 1 -->
      <div class="imgBox img1">
        <!-- 碗 -->
        <div v-show="isShow1" class="wanBox wan1" :style="{left:wan1.left+'px',top:wan1.top+'px',height:wan1.height+'px',width:wan1.width+'px'}" alt="" @click="showDialog(1)" />
        <!-- 按钮 -->
        <!-- <div class="anniuBox anniuImg1" src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn1.png" alt="" @click="showDialog(1)" /> -->
        <!-- 空碗 -->
        <div v-show="!isShow1" class="kongwanBox " :style="{left:kongwan1.left+'px',top:kongwan1.top+'px',height:kongwan1.height+'px',width:kongwan1.width+'px'}" />
        <!-- 工具 -->
        <div v-show="!isShow1" class="gongjuBox gongju1" :style="{left:gongju1.left+'px',top:gongju1.top+'px',height:gongju1.height+'px',width:gongju1.width+'px'}" @touchstart="thStart" @touchmove.stop="thMove" @touchend="thEnd" />

        <!-- 移动的手 -->

        <div v-show="!isShow1" class="shouBox shou1" :style="{left:shou1.left+'px',top:shou1.top+'px'}" />
        <div v-show="!isShow1" class="wenziBox wenzi1" :style="{left:wenzi1.left+'px',top:wenzi1.top+'px'}" />
        <!--  -->
        <!-- <div class="bg1">

                </div> -->
      </div>
      <!-- 2 -->
      <div class="imgBox img2">
        <!-- 碗 -->
        <div class="wanBox wan2 " :class="{newDD:isShowTwo}" :style="{left:wan2.left+'px',top:wan2.top+'px',height:wan2.height+'px',width:wan2.width+'px'}" alt="" @click="showDialog(2)" />
        <!-- 按钮 -->
        <!-- <div class="anniuBox anniuImg2" src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn2.png" alt="" @click="showDialog(2)" /> -->
        <div class="shouBox shou2" :style="{left:shou2.left+'px',top:shou2.top+'px'}" />
        <div class="wenziBox wenzi2" :style="{left:wenzi2.left+'px',top:wenzi2.top+'px'}" />
      </div>
      <!-- 3 -->
      <div class="imgBox img3">
        <!-- 碗 -->
        <div v-show="isShow3" class="wanBox wan3" :style="{left:wan3.left+'px',top:wan3.top+'px',height:wan3.height+'px',width:wan3.width+'px'}" alt="" @click="showDialog(3)" />
        <!-- 按钮 -->
        <!-- <div class="anniuBox anniuImg3" src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn3.png" alt="" @click="showDialog(3)" /> -->
        <!-- 空碗 -->
        <div v-show="!isShow3" class="kongwanBox " :style="{left:kongwan3.left+'px',top:kongwan3.top+'px',height:kongwan3.height+'px',width:kongwan3.width+'px'}" />
        <!-- 工具 -->
        <div v-show="!isShow3" class="gongjuBox gongju3" :style="{left:gongju3.left+'px',top:gongju3.top+'px',height:gongju3.height+'px',width:gongju3.width+'px'}" @touchstart="thStart3" @touchmove.stop="thMove3" @touchend="thEnd3" />
        <!-- 移动的手 -->
        <div v-show="!isShow3" class="shouBox shou3" :style="{left:shou3.left+'px',top:shou3.top+'px'}" />
        <div v-show="!isShow3" class="wenziBox wenzi3" :style="{left:wenzi3.left+'px',top:wenzi3.top+'px'}" />
      </div>
      <!-- 4 -->
      <div class="imgBox img4">
        <!-- 碗 -->
        <div v-show="isShow4" class="wanBox wan4" :style="{left:wan4.left+'px',top:wan4.top+'px',height:wan4.height+'px',width:wan4.width+'px'}" alt="" @click="showDialog(4)" />
        <!-- 按钮 -->
        <!-- <div class="anniuBox anniuImg4" src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn4.png" alt="" @click="showDialog(4)" /> -->
        <!-- 空碗 -->
        <div v-show="!isShow4" class="kongwanBox " :style="{left:kongwan4.left+'px',top:kongwan4.top+'px',height:kongwan4.height+'px',width:kongwan4.width+'px'}" @click="showDialog(4)" />
        <!-- 工具 -->
        <div v-show="!isShow4" class="gongjuBox gongju4" :style="{left:gongju4.left+'px',top:gongju4.top+'px',height:gongju4.height+'px',width:gongju4.width+'px'}" @touchstart="thStart4" @touchmove.stop="thMove4" @touchend="thEnd4" />
        <!-- 移动的手 -->

        <div v-show="!isShow4" class="shouBox shou4" :style="{left:shou4.left+'px',top:shou4.top+'px'}" />
        <div v-show="!isShow4" class="wenziBox wenzi4" :style="{left:wenzi4.left+'px',top:wenzi4.top+'px'}" />
      </div>
      <!-- 5 -->
      <div class="imgBox img5">
        <!-- 碗 -->
        <div v-show="isShow5" class="wanBox wan5" :style="{left:wan5.left+'px',top:wan5.top+'px',height:wan5.height+'px',width:wan5.width+'px'}" alt="" @click="showDialog(5)" />
        <!-- 按钮 -->
        <!-- <div class="anniuBox anniuImg5" src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn5.png" alt="" @click="showDialog(5)" /> -->
        <!-- 空碗 -->
        <div v-show="!isShow5" class="kongwanBox " :style="{left:kongwan5.left+'px',top:kongwan5.top+'px',height:kongwan5.height+'px',width:kongwan5.width+'px'}" />
        <!-- 工具 -->
        <div v-show="!isShow5" class="gongjuBox gongju5" :style="{left:gongju5.left+'px',top:gongju5.top+'px',height:gongju5.height+'px',width:gongju5.width+'px'}" @touchstart="thStart5" @touchmove.stop="thMove5" @touchend="thEnd5" />
        <!-- 移动的手 -->

        <div v-show="!isShow5" class="shouBox shou5" :style="{left:shou5.left+'px',top:shou5.top+'px'}" />
        <div v-show="!isShow5" class="wenziBox wenzi5" :style="{left:wenzi5.left+'px',top:wenzi5.top+'px'}" />
      </div>
    </div>
    <!-- <audio ref="bjAudio" src="https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/daojishi.mp3" id="bgaudio" autoplay loop="loop" controls="controls">

        </audio> -->
    <audio controls="controls" style="display:none">
      <source src="http://dict.youdao.com/dictvoice?audio=eureka&type=3" type="audio/ogg">
      优先播放音乐bg.ogg，不支持在播放bg.mp3
    </audio>
    <audio
      id="bjMusic"
      ref="initAudio"
      style="display:none"
      controls="controls"
      autoplay
      loop
      :src="musicUrl"
    />
    <!-- 遮罩 -->
    <div v-if="isShowDialog" class="dialogMask" @click="closeDialogFunc" />
    <div id="dialogBox" class="dialogBox " :class="{activeDialogBox:isShowDialog}">
      <!-- :style="{height:height+'px',width:width+'px'}" -->
      <div style="position: relative;height: 100%;width: 100%;">
        <img v-if="isShowNewImg" class="dialogImg" :src="imgSrc" alt="">
        <div v-if="isShowClose" class="closeImg" @click="closeDialogFunc" />
        <!-- <canvas
          v-show="!isScratch"
          id="c1"
          class="canvas"
          :style="{height:height+'px',width:width+'px'}"
          @touchmove="touchmove"
          @touchstart="touchstart"
          @touchend="touchend"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as PIXI from 'pixi.js'
// import from './utils/index.js'
import locationJs from './components/location'
import { getWxPz } from '@/api/public.js'
import wx from 'weixin-js-sdk'
export default {
  name: 'App',
  components: {
  },
  mixins: [locationJs],
  data() {
    return {
      isShow: true,
      progress: 0,
      resources: [],
      timer: null,
      num: 0,
      isShowDialog: false,
      isShowDialogContent: false,
      height: 500,
      width: 350,
      imgSrc: '',
      showMp3: false,
      musicUrl: '',
      isShowTwo: false,
      //
      isShowNewImg: false,
      isClose: false,
      isShowhb: false,
      c1: '', // 画布
      ctx: '', // 画笔
      ismousedown: false, // 标志用户是否按下鼠标或开始触摸
      fontem: '', // 获取html字体大小
      isScratch: false, // 是否刮过卡
      showPrize: false, // 显示奖品,
      //   关闭按钮
      isShowClose: false
    }
  },
  mounted() {
    this.musicUrl = 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/beijingMusic.mp3'
    document.addEventListener('touchstart', function() {}, false)

    // this.loadTexture();
    // this.isShow = false
    // this.initPlay()
    getWxPz({ url: 'https://wuyi.hzbuen.com/h5/', nonceStr: parseInt(Math.random() * 1000) }).then(res => {
      console.log(res, 'res')
      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: res.appId ? res.appId : 'wx30be974c140a7e15', // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的 JS 接口列表
      })
      wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: '清风茶韵品五义', // 分享标题，
          desc: '让我们泡一杯清茶，制一回宋代点茶，赏大美进化，共品“五义”精神，吹拂清廉之风！',
          link: 'https://wuyi.hzbuen.com/h5', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/fenxiang.png', // 分享图标
          success: function() {
          // 设置成功
          }
        })
        wx.updateTimelineShareData({
          title: '清风茶韵品五义', // 分享标题
          link: 'https://wuyi.hzbuen.com/h5', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/fenxiang.png', // 分享图标
          success: function() {
            // 设置成功
          }
        })
      })
    })

    this.loadingImg()
  },
  methods: {
    audioAutoPlay() {
      var audio = document.getElementById('bjMusic')
      var play = function() {
        audio.play()
        document.removeEventListener('touchstart', play, false)
      }
      audio.play()
      document.addEventListener('WeixinJSBridgeReady', function() {
        play()
      }, false)
      document.addEventListener('YixinJSBridgeReady', function() {
        play()
      }, false)
      document.addEventListener('touchstart', play, false)
    },
    initPlay() {
      document.addEventListener('WeixinJSBridgeReady', function() {
        document.getElementById('bjMusic').play()
      }, false)
    },
    // init(){
    //     if (!document.getElementById("music")) {
    //     if (!this.showMp3) {
    //         getMusic("mc_play", false);
    //     } else {
    //         getMusic("mc_play");
    //     }
    //     }
    // },
    loadingImg() {
      new PIXI.Loader()
        .add('img1', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/longbanner.jpg')
        .add('img2', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/startLoading.png')
        .add('dialog1', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page1.gif')
        .add('dialog2', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page2.gif')
        .add('dialog3', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page3.gif')
        .add('dialog4', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page4.gif')
        .add('dialog5', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page5.gif')
        .add('btn1', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn1.png')
        .add('btn2', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn2.png')
        .add('btn3', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn3.png')
        .add('btn4', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn4.png')
        .add('btn5', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn5.png')
        .add('gif1', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/1.gif')
        .add('gif2', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/2.gif')
        .add('gif3', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/3.gif')
        .add('gif4', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/44.gif')
        .add('gif5', 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/5.gif')
        .on('progress', (e) => {
          this.jisuanBFB(e.progress)
        })
        .load(setup)
      function setup() {
        console.log('All files loaded')

        //    console.log(e)
      }
    },
    jisuanBFB(num) {
      const timer = setInterval(() => {
        this.progress++
        if (this.progress >= num) {
          clearInterval(timer)
          if (num >= 99.99) {
            setTimeout(() => {
              this.isShow = false
              this.audioAutoPlay()
            }, 800)
          }
        }
      }, 100)
    },
    loadTexture() {
      var loader = new PIXI.Loader()
      //   console.log(loader)
      loader.add(this.list)
      //   console.log(loader)

      loader.on('progress', (e) => {
        console.log(e, 'hhh')
      })
      loader.load((e) => {
        this.progress = e.progress
        this.resources = e.resources
      })
    },
    showDialog(num, duration = 0) {
    //   console.log(num)
      this.isScratch = false
      this.isShowNewImg = true
      if (num === 2) {
        // this.isShowTwo = true
        setTimeout(() => {
        //   this.isShowDialog = true
          this.isShowTwo = false

          this.imgSrc = 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page' + num + '.gif'
          this.$nextTick(() => {
            this.isShowDialog = true
            this.isShowClose = true
            // this.height = 500
            // this.width = 350
            this.gundongFunc(0)
            setTimeout(() => {

            //
            }, 500)
            // 刮刮乐
            // this.showFunc(num)
          })
          setTimeout(() => {
            this.isClose = true
          }, 2000)
        }, duration)
      } else {
        setTimeout(() => {
        //   this.isShowDialog = true
          this.$nextTick(() => {
            this.isShowDialog = true
            this.isShowClose = true

            this.imgSrc = 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page' + num + '.gif'
            // this.height = 500
            // this.width = 350
            // this.imgSrc = 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page' + num + '.gif'
            this.gundongFunc(0)
            setTimeout(() => {
            //   this.imgSrc = 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page' + num + '.gif'
            //   this.isShowNewImg = true
            }, 500)
            // 刮刮乐

            // this.showFunc(num)
          })
          setTimeout(() => {
            this.isClose = true
          }, 2000)
        }, duration)
      }
    },
    closeDialogFunc() {
      if (!this.isClose) {
        return false
      }
      this.isShowDialog = false
      this.isClose = false
      this.$nextTick(() => {
        this.gundongFunc(1)
        this.isShowNewImg = false
        this.isShowClose = false

        this.imgSrc = ''
        // this.height = 0
        // this.width = 0
      })
    },
    // 禁止和恢复滚动
    gundongFunc(num) {
      if (num === 0) {
        document.body.addEventListener('touchmove', this.bodyScrollFunc, { passive: false })
      } else if (num === 1) {
        document.body.removeEventListener('touchmove', this.bodyScrollFunc, { passive: false })
      }
    },
    bodyScrollFunc(e) {
      e.preventDefault()
    },
    thStart(e) {
    //   console.log(e)
      document.body.style.overflow = 'hidden'
      document.body.addEventListener('touchmove', this.bodyScrollFunc, { passive: false })
    },
    // 移动进行拖拽
    thMove(e) {
    //   console.log(e)
      const left = Number(e.touches[0].pageX) - this.gongju1.width / 2
      const top = Number(e.touches[0].pageY) - this.gongju1.height / 2
      if (top > this.kongwan1.top) {
        this.gongju1.top = this.kongwan1.top
      } else if (top < this.kongwan1.top - 200) {
        this.gongju1.top = this.kongwan1.top - 200
      } else {
        this.gongju1.top = top
      }
      if (left > 300 - this.gongju1.width / 2) {
        this.gongju1.left = 300 - this.gongju1.width / 2
      } else if (left < 0) {
        this.gongju1.left = 0
      } else {
        this.gongju1.left = left
      }
    //   console.log(this.wan1.left)
    },
    thEnd() {
      this.gundongFunc(1)
      document.body.style.overflow = 'auto'
      const bl1 = this.gongju1.top <= this.kongwan1.top - 20
      const bl2 = this.gongju1.top >= this.kongwan1.top - this.kongwan1.height / 2 - 40
      const bl3 = this.gongju1.left >= this.kongwan1.left - 20
      const bl4 = this.gongju1.left <= this.kongwan1.left + this.kongwan1.width / 2
      if (bl1 && bl2 && bl3 && bl4) {
        // console.log(true)
        this.isShow1 = true
        this.showDialog(1, 1500)
      }
    },
    thStart3(e) {
    //   console.log(e)
      document.body.style.overflow = 'hidden'
      document.body.addEventListener('touchmove', this.bodyScrollFunc, { passive: false })
    },
    // 移动进行拖拽
    thMove3(e) {
    //   console.log(e)
      const left = Number(e.touches[0].pageX) - this.gongju3.width / 2
      const top = Number(e.touches[0].pageY) - this.gongju3.height / 2
      if (top > this.kongwan3.top) {
        this.gongju3.top = this.kongwan3.top
      } else if (top < this.kongwan3.top - 200) {
        this.gongju3.top = this.kongwan3.top - 200
      } else {
        this.gongju3.top = top
      }
      if (left > 300 - this.gongju3.width / 2) {
        this.gongju3.left = 300 - this.gongju3.width / 2
      } else if (left < 0) {
        this.gongju3.left = 0
      } else {
        this.gongju3.left = left
      }
    //   console.log(this.wan1.left)
    },
    thEnd3() {
      this.gundongFunc(1)
      document.body.style.overflow = 'auto'
      const bl1 = this.gongju3.top <= this.kongwan3.top
      const bl2 = this.gongju3.top >= this.kongwan3.top - this.kongwan3.height / 2
      console.log(this.gongju3)
      const bl3 = this.gongju3.left >= this.kongwan3.left - this.gongju3.width
      const bl4 = this.gongju3.left <= this.kongwan3.left + this.kongwan3.width / 2
      console.log(bl1, bl2, bl3, bl4)
      if (bl1 && bl2 && bl3 && bl4) {
        this.isShow3 = true
        this.showDialog(3, 1500)
      }
    },
    thStart4(e) {
    //   console.log(e)
      document.body.style.overflow = 'hidden'
      document.body.addEventListener('touchmove', this.bodyScrollFunc, { passive: false })
    },
    // 移动进行拖拽
    thMove4(e) {
    //   console.log(e)
      const left = Number(e.touches[0].pageX) - this.gongju4.width / 2 - 20
      const top = Number(e.touches[0].pageY) - this.gongju4.height / 2
      if (top > this.kongwan4.top) {
        this.gongju4.top = this.kongwan4.top
      } else if (top < this.kongwan4.top - 200) {
        this.gongju4.top = this.kongwan4.top - 200
      } else {
        this.gongju4.top = top
      }
      //   console.log(left)
      //   console.log(280 - this.gongju4.width / 2)
      if (left > 280 - this.gongju4.width / 2) {
        this.gongju4.left = 280 - this.gongju4.width / 2
      } else if (left < 0) {
        this.gongju4.left = 0
      } else {
        this.gongju4.left = left
      }
    //   console.log(this.wan1.left)
    },
    thEnd4() {
      this.gundongFunc(1)
      document.body.style.overflow = 'auto'
      const bl1 = this.gongju4.top <= this.kongwan4.top - 20
      const bl2 = this.gongju4.top >= this.kongwan4.top - this.kongwan4.height / 2 - 80
      //   console.log(this.gongju4.top)
      //   console.log(this.kongwan4.top - this.kongwan4.height / 2 - 20)
      const bl3 = this.gongju4.left >= this.kongwan4.left - 20 - this.gongju4.width
      const bl4 = this.gongju4.left <= this.kongwan4.left + this.kongwan4.width / 2
      //   console.log(bl1, bl2, bl3, bl4)
      if (bl1 && bl2 && bl3 && bl4) {
        this.isShow4 = true
        this.showDialog(4, 1500)
      }
    },
    thStart5(e) {
    //   console.log(e)
      document.body.style.overflow = 'hidden'
      document.body.addEventListener('touchmove', this.bodyScrollFunc, { passive: false })
    },
    // 移动进行拖拽
    thMove5(e) {
    //   console.log(e)
      const left = Number(e.touches[0].pageX) - this.gongju5.width / 2
      const top = Number(e.touches[0].pageY) - this.gongju5.height / 2
      if (top > this.kongwan5.top) {
        this.gongju5.top = this.kongwan5.top
      } else if (top < this.kongwan5.top - 200) {
        this.gongju5.top = this.kongwan5.top - 200
      } else {
        this.gongju5.top = top
      }
      if (left > 300 - this.gongju5.width / 2) {
        this.gongju5.left = 300 - this.gongju5.width / 2
      } else if (left < 0) {
        this.gongju5.left = 0
      } else {
        this.gongju5.left = left
      }
    //   console.log(this.wan1.left)
    },
    thEnd5() {
      this.gundongFunc(1)
      document.body.style.overflow = 'auto'
      const bl1 = this.gongju5.top <= this.kongwan5.top - 20
      const bl2 = this.gongju5.top >= this.kongwan5.top - this.kongwan5.height / 2 - 90

      const bl3 = this.gongju5.left >= this.kongwan5.left - 20 - this.gongju5.width
      const bl4 = this.gongju5.left <= this.kongwan5.left + this.kongwan5.width / 2
      if (bl1 && bl2 && bl3 && bl4) {
        // console.log(true)
        this.isShow5 = true
        this.showDialog(5, 1500)
      }
    }

  }
}
</script>

<style>
body{
    margin: 0;
}
</style>

<style  >
body,html,#app{
    height: 100%;
    width: 100%;
    background: rgb(181, 216, 206);

}
.pageBox{
    /* height: 100%; */
    width: 100%;
    /* background-image: url('
https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/longBg.png'); */
    /* background-repeat: no-repeat; */
    background: rgb(181, 216, 206);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.loadingImgBox{
    width: 100%;
    height: 100%;
    max-width: 375px;
    max-height: 667px;
    margin: auto;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.loadingImg-item{
    height: 100%;
    width: 100%;
    position: relative;
}
.loadingImg-item img{
    display: block;
    height: 100%;
    width: 100%;
}
.loading-num{
    position: absolute;
    bottom: 10%;
    text-align: center;
    width: 100%;
    font-size: 30px;

}
.longBanner{
    width: 375px;
    margin:  0 auto;
    position: relative;

}
.longBanner img{
    width: 375px;
    display: block;
}
.imgBox{
}

/* .img1 .bg1{
    background: url('
https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/page1.png');
    position: absolute;
    height: 160px;
    width: 140px;
    top: 1600px;
    left: 120px;
}
.img1 .bg1:active{
    transform: translate(10px,10px);

} */
.img1 .wan1{
    position: absolute;
    /* height: 160px;
    width: 140px; */
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/1.gif');
    background-size: 100% 100%;

    /* top: 1600px;
    left: 120px; */

}
.img1 .anniuImg1{
    position: absolute;
    height: 60px;
    width: 130px;
    top: 2060px;
    left: 30px;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn1.png');
    background-size: 100% 100%;
    z-index: 2;

}
.kongwanBox{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/allWan.png');
    background-size: 100% 100%;
    position: absolute;
}
.gongjuBox{
    position: absolute;
    z-index: 3;
}
.gongju1{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/gongju1.png');
    background-size: 100% 100%;
}
.gongju3{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/gongju3.png');
    background-size: 100% 100%;
}
.gongju4{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/gongju4.png');
    background-size: 100% 100%;
}
.gongju5{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/gongju5.png');
    background-size: 100% 100%;
}
.img2 .wan2{
    position: absolute;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/2.gif');
    background-size: 100% 100%;
    /* height: 160px;
    width: 140px; */
    /* top: 2120px;
    left: 120px; */

}
.img2 .anniuImg2{
    position: absolute;
    height: 70px;
    width: 130px;
    top: 2420px;
    left: 215px;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn2.png');
    background-size: 100% 100%;
    z-index: 2;

}
.img3 .wan3{
    position: absolute;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/3.gif');
    background-size: 100% 100%;
    /* height: 160px;
    width: 140px; */
    /* top: 2520px;
    left: 120px; */
}
.img3 .anniuImg3{
    position: absolute;
    height: 60px;
    width: 140px;
    top: 3120px;
    left: 60px;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn3.png');
    background-size: 100% 100%;
    z-index: 2;

}
.img4 .wan4{
    position: absolute;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/44.gif');
    background-size: 100% 100%;
    z-index: 2;
    /* height: 240px;
    width: 200px; */
    /* top: 2820px;
    right: 40px; */
}
.img4 .anniuImg4{
    position: absolute;
    height: 60px;
    width: 140px;
    top: 3650px;
    right: 40px;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn4.png');
    background-size: 100% 100%;
    z-index: 2;

}

.img5 .wan5{
    position: absolute;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/5.gif');
    background-size: 100% 100%;
    /* height: 210px;
    width: 140px; */
    /* top: 3250px;
    left: 120px; */
}

.img5 .anniuImg5{
    position: absolute;
    height: 60px;
    width: 160px;
    top: 4160px;
    left: 80px;
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/btn5.png');
    background-size: 100% 100%;
    z-index: 2;

}
/* .wanBox:active{
    transform: translate(1px,1px);
} */
.anniuBox:active{
    transform: translate(1px,1px);
}

.dialogMask{
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
}
.dialogBox{
    height: 0 ;
    width: 0;
    position: fixed;
    /* left: 50%; */
    /* top: 50%; */
    /* margin-left: -175px; */
    /* margin-top:-250px; */
    height: 90%;
    width: 90%;
    left:0;
    top:0;
    right:0;
    bottom: 0;
    margin: auto;
    /* background: #fff; */
    z-index: 6;
    transition: all 0.5s;
    overflow: hidden;
    transform: scale(0);

}
.activeDialogBox{
    transform: scale(1);

}
.dialogImg{
    height: 100%;
    width:100%;
    display: block;
    /* -webkit-filter: grayscale(100%) brightness(1) contrast(.5);
	-moz-filter: grayscale(100%) brightness(1) contrast(.5);
	-ms-filter: grayscale(100%) brightness(1) contrast(.5);
	-o-filter: grayscale(100%) brightness(1) contrast(.5);
	filter: grayscale(100%) brightness(1) contrast(.5);
	-webkit-filter: grey opacity(.5);
	filter: grey opacity(.5) */
}
.closeImg{
    height: 30px;
    width: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/close.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
img {
    -webkit-touch-callout: none;
    pointer-events: none;
    /* // 像微信浏览器还是无法禁止，加上这行样式即可 */
}
div {
        -webkit-user-select: none;
    }
    .newDD{
        animation: move 2s  infinite;
        -webkit-animation: move 2s  infinite;
        transform-origin: bottom;
        -webkit-transform-origin: bottom;
    }
    @keyframes move {

	0%{
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
    25%{
		-webkit-transform: rotate(6deg);
		transform: rotate(6deg);
	}
    50%{
		-webkit-transform: rotate(-6deg);
		transform: rotate(-6deg);
	}

	75% {
		-webkit-transform: rotate(6deg);
		transform: rotate(6deg);
	}

	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}
.shouClass{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/shou.png');
    background-size: 100% 100%;
    height: 100px;
    width: 100px;
    position: absolute;
    left: 30px;
    top: 30px;
     animation: moves 1s  infinite;
    -webkit-animation: moves 1s  infinite;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
}

@keyframes moves {

	0%{
		left: 30px;
	}

    50%{
		left: 40px;
    }
	100% {
		left: 30px;
	}
}

.canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
#c1{
    filter: grey opacity(.5)
}
.shouBox{
    position: absolute;
}
.shou1{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/shou1.png');
    background-size: 100% 100%;
    height: 50px;
    width: 50px;
    animation: move1 1s  infinite;
    -webkit-animation: move1 1s  infinite;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
}
.shou2{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/shou2.png');
    background-size: 100% 100%;
    height: 50px;
    width: 50px;
    animation: move2 1s  infinite;
    -webkit-animation: move2 1s  infinite;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
}
.shou3{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/shou3.png');
    background-size: 100% 100%;
    height: 50px;
    width: 50px;
    animation: move3 1s  infinite;
    -webkit-animation: move3 1s  infinite;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
}
.shou4{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/shou4.png');
    background-size: 100% 100%;
    /* left: 0; */
    /* top: 0; */
    height: 50px;
    width: 50px;
    animation: move4 1s  infinite;
    -webkit-animation: move4 1s  infinite;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
}
.shou5{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/shou5.png');
    background-size: 100% 100%;
    /* left: 0; */
    /* top: 0; */
    height: 50px;
    width: 50px;
    animation: move5 1s  infinite;
    -webkit-animation: move5 1s  infinite;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
}
@keyframes move1 {

	0%{
		left: 220px;
	}

    50%{
		left: 210px;
    }
	100% {
		left: 220px;
	}
}
@keyframes move2{

	0%{
		left: 230px;
	}

    50%{
		left: 220px;
    }
	100% {
		left: 230px;
	}
}
@keyframes move3 {

	0%{
		left: 150px;
	}

    50%{
		left: 160px;
    }
	100% {
		left: 150px;
	}
}
@keyframes move4 {

	0%{
		left: 190px;
	}

    50%{
		left: 200px;
    }
	100% {
		left: 190px;
	}
}
@keyframes move5 {

	0%{
		left: 150px;
	}

    50%{
		left: 160px;
    }
	100% {
		left: 150px;
	}
}
.wenziBox{
    position: absolute;
}
.wenzi1{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/hdzc.png');
    background-size: 100% 100%;
    height:45px;
    width:80px;
}
.wenzi2{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/djcz.png');
    background-size: 100% 100%;
    height:35px;
    width:80px;
}
.wenzi3{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/ydcs.png');
    background-size: 100% 100%;
    height:50px;
    width:100px;
}
.wenzi4{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/ydsh.png');
    background-size: 100% 100%;
    height:45px;
    width:80px;
}
.wenzi5{
    background: url('https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/ydzx.png');
    background-size: 100% 100%;
    height:40px;
    width:80px;
}
</style>
