import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'

let baseURL = ''

if (process.env.NODE_ENV === 'development') { // 开发环境
  baseURL = 'https://wuyi.hzbuen.com'
//   baseURL = 'http://172.16.17.2:10020/dc-api/'
} else {
  baseURL = 'https://wuyi.hzbuen.com'
}

// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  timeout: 20000, // request timeout,
  headers: {

  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    return response.data
  },
  error => {
    const errorStatus = error.response.status
    if (errorStatus === 401) {
    //   Message({
    //     message: '登录已超时，请重新登陆',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
      //   window.location.href = '/dc-web/login'
      //   window.location.href = '/dc-station-web/login'
    } else if (errorStatus === 403) {

      //   window.location.href = '/dc-web/403'
      //   window.location.href = '/dc-station-web/login'
    } else if (errorStatus === 503) {
      if (error.response.data.resultHint) {
        // Message({
        //   message: error.response.data.resultHint,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
      } else {
        // Message({
        //   message: '服务器忙碌，请稍后',
        //   type: 'error',
        //   duration: 5 * 1000
        // })
      }
    } else if (errorStatus >= 500 && errorStatus < 600) {
    //
    }

    return Promise.reject(error)
  }
)

export default service
