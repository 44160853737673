export default {
  data() {
    return {
      isShow1: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      // 1
      wan1: { left: 100, top: 1930, height: 170, width: 190 },
      // 2
      wan2: { left: 100, top: 2450, height: 160, width: 170, isShow: false },
      // 3
      wan3: { left: 60, top: 2936, height: 180, width: 210, isShow: false },
      // 4
      wan4: { left: 115, top: 3490, height: 210, width: 255, isShow: false },
      // 5
      wan5: { left: 90, top: 3963, height: 180, width: 190, isShow: false },
      // kongwan1
      kongwan1: { left: 112, top: 1970, height: 140, width: 160 },
      gongju1: { left: 210, top: 1800, width: 160, height: 180 },
      // kongwan3
      kongwan3: { left: 95, top: 2975, height: 140, width: 160 },
      gongju3: { left: 40, top: 2900, width: 160, height: 100 },
      // kongwan4
      kongwan4: { left: 120, top: 3570, height: 145, width: 155 },
      gongju4: { left: 160, top: 3380, width: 210, height: 220 },
      // kongwan5
      kongwan5: { left: 100, top: 4010, height: 145, width: 160 },
      gongju5: { left: 60, top: 3820, width: 160, height: 200 },
      //   手1,文字1
      shou1: { left: 220, top: 1870 },
      wenzi1: { left: 200, top: 1840 },
      //   手2,文字2
      shou2: { left: 200, top: 2460 },
      wenzi2: { left: 210, top: 2445 },
      //   手3,文字3
      shou3: { left: 160, top: 2930 },
      wenzi3: { left: 140, top: 2910 },
      //   手4,文字4
      shou4: { left: 190, top: 3400 },
      wenzi4: { left: 180, top: 3370 },
      //   手5,文字5
      shou5: { left: 120, top: 3850 },
      wenzi5: { left: 145, top: 3830 }

    }
  },
  methods: {
    showFunc(num) {
      this.isShowhb = true
      this.$nextTick(() => {
        this.fontem = parseInt(
          window.getComputedStyle(document.documentElement, null)['font-size']
        )
        // 这是为了不同分辨率上配合@media自动调节刮的宽度
        this.c1 = document.getElementById('c1')

        // 这里很关键，canvas自带两个属性width、height,我理解为画布的分辨率，跟style中的width、height意义不同。
        // 最好设置成跟画布在页面中的实际大小一样
        // 不然canvas中的坐标跟鼠标的坐标无法匹配
        this.c1.width = this.c1.clientWidth + 15
        this.c1.height = this.c1.clientHeight
        this.ctx = this.c1.getContext('2d')

        this.initCanvas(num)
      })
    },
    // 画刮刮卡
    initCanvas(num) {
      this.ctx.globalCompositeOperation = 'source-over'

      //   this.ctx.fillStyle = 'rgba(0,0,0,0.3)'
      //   this.ctx.fillRect(0, 0, this.c1.width, this.c1.clientHeight)

      var imgObj = new Image()
      imgObj.src = 'https://buen-video.oss-cn-beijing.aliyuncs.com/wuyi/assets/' + num + '.png'

      this.ctx.drawImage(imgObj, 0, 0, 350 + 15, 500)
      const imageData = this.ctx.getImageData(0, 0, 350 + 15, 500)
      const data = imageData.data
      for (var i = 0; i < data.length; i += 4) {
        var avg = (data[i] + data[i + 1] + data[i + 2]) / 3
        data[i] = avg // red
        data[i + 1] = avg // green
        data[i + 2] = avg // blue
      }
      this.ctx.putImageData(imageData, 0, 0)

      this.ctx.font = 'Bold 22px Arial'
      this.ctx.textAlign = 'center'
      this.ctx.fillStyle = '#000'
      this.ctx.fillText('手指涂抹上色', (350 + 15) / 2, 60)

      this.ctx.fill()

      //   this.ctx.fillText("刮开有奖", this.c1.width / 2, 55);
      // 有些老的手机自带浏览器不支持destination-out,下面的代码中有修复的方法
      this.ctx.globalCompositeOperation = 'destination-out'
    },
    touchstart(e) {
      e.preventDefault()
      this.ismousedown = true
    },
    // 操作刮卡
    touchend(e) {
      sessionStorage.setItem('isScratch', true)
      e.preventDefault()
      // 得到canvas的全部数据
      var a = this.ctx.getImageData(0, 0, this.c1.width, this.c1.height)
      var j = 0
      for (var i = 3; i < a.data.length; i += 4) {
        if (a.data[i] === 0) j++
      }
      // 当被刮开的区域等于一半时，则可以开始处理结果
      if (j >= a.data.length / 15) {
        this.isScratch = true
      }
      this.ismousedown = false
    },
    touchmove(e) {
    //   console.log(e)
      this.showPrize = true
      e.preventDefault()
      if (!this.isClose) return false

      if (this.ismousedown) {
        if (e.changedTouches) {
          e = e.changedTouches[e.changedTouches.length - 1]
        }
        var topBox = document.getElementById('dialogBox')
        // console.log(topBox, 'topBox')
        var topY = topBox.offsetTop
        // console.log(topY, 'topY')
        var oX = this.c1.offsetLeft
        var oY = this.c1.offsetTop + topY

        var x = (e.clientX + document.body.scrollLeft || e.pageX) - oX - document.getElementById('dialogBox').offsetLeft || 0
        var y = (e.clientY || e.pageY) - oY || 0

        // 画360度的弧线，就是一个圆，因为设置了ctx.globalCompositeOperation = 'destination-out';
        // 画出来是透明的
        // console.log(x, y)
        this.ctx.beginPath()
        this.ctx.arc(x, y, this.fontem * 2.5, 0, Math.PI * 2) // 调整画笔的大小
        // 下面3行代码是为了修复部分手机浏览器不支持destination-out
        // 我也不是很清楚这样做的原理是什么
        this.c1.style.display = 'none'
        this.c1.offsetHeight
        this.c1.style.display = 'inherit'
        this.ctx.fill()
        // console.log(this.c1, 'c1')

        // console.log(this.ctx)
      }
    }
  }
}
